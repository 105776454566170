import {FC, useEffect, useState} from "react";
import PageContainer from "../../components/UI/PageContainer/PageContainer";
import PageWrapper from "../../components/PageWrapper/PageWrapper";
import styles from './AdvancedAnalyticsPage.module.scss';
import AdvancedAnalyticsPageHeader from "./AdvancedAnalyticsPageHeader/AdvancedAnalyticsPageHeader";
import AnalyticsReportHistoryTable
    from "../../components/Tables/AnalyticsReportHistoryTable/AnalyticsReportHistoryTable";
import {usePagination} from "../../hooks/usePagination";
import TablePagination from "../../components/TablePagination/TablePagination";
import NoDataComponent from "../../components/NoDataComponent/NoDataComponent";
import { countries } from "countries-list";
import Modal from "../../components/Modals/Modal";
import CreateAnalyticsPresetModal from "../../components/Modals/CreateAnalyticsPresetModal/CreateAnalyticsPresetModal";
import {useAPI} from "../../hooks/useAPI";
import {ReportsService} from "../../API/ReportsService";
import {useAppContext} from "../../hooks/useAppContext";
import {useAfterMountEffect} from "../../hooks/useAfterMountEffect";
import {get} from "node:http";
import PageLoading from "../../components/UI/PageLoading/PageLoading";
import {AnalyticsReport, CreateReportRequestData} from "../../types/AnalyticsReports/AnalyticsReports";

// @ts-ignore
const countriesNames = Object.keys(countries).map((key) => countries[key].name);

const availableProducts = ['Lamine Pro1', 'Free Subscription1', 'Lamine Pro2', 'Free Subscription2', 'Xavi Free'];
const availableCountries = countriesNames;
// const reports = ['1', '2', '3', '4', '5', '6', '7'];

const AdvancedAnalyticsPage: FC = () => {
    const [analyticsOptions, setAnalyticsOptions] = useState<Record<string, any>>({
        products: [...availableProducts],
        customer: 'All',
        countries: [...availableCountries],
        dateRange: 'This Month',
        dateRangeFrom: null,
        dateRangeTo: null,
        compareToPrevious: false,
        compareFromDate: new Date(),
        compareToDate: new Date(),
    });
    const [chosenReports, setChosenReports] = useState<any[]>([]);
    const [createPresetModalIsOpen, setCreatePresetModalIsOpen] = useState(false);

    const {
        pageSize,
        currentPage,
        updateTotalItems,
        totalItems,
        setCurrentPage,
        setPageSize
    } = usePagination();
    const { shop } = useAppContext();
    const usersTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

    const [reportsLoading, reports, getReports] = useAPI<AnalyticsReport[]>(
        () => ReportsService.getReports(shop?.id || ''),
        false,
        undefined,
    );

    const [createReportLoading, , createReport] = useAPI(
        () => ReportsService.createReport(shop?.id || '', {
            type: "PRODUCTS",
            timezone: usersTimezone,
            fromDate: analyticsOptions.dateRangeFrom?.toISOString().split('T', 1)[0],
            toDate: analyticsOptions.dateRangeTo?.toISOString().split('T', 1)[0],
            compareFromDate: analyticsOptions.compareToPrevious? analyticsOptions.compareFromDate.toISOString().split('T', 1)[0] : null,
            compareToDate: analyticsOptions.compareToPrevious? analyticsOptions.compareToDate.toISOString().split('T', 1)[0] : null,
            productsFilter: null,
            countryFilter: ['Russia'],
            newCustomersFilter: analyticsOptions.customer === 'All'
                                ? null
                                : analyticsOptions.customer === 'New'
        }),
        false,
        undefined,
        {
            onSuccess: getReports
        }
    );

    useEffect(() => {
        shop && getReports();
    }, [shop]);

    if (reportsLoading) {
        return <PageLoading />;
    }

    return (
        <PageContainer>
            <PageWrapper>
                {
                    createPresetModalIsOpen && (
                        <Modal
                            closeModal={() => {setCreatePresetModalIsOpen(false)}}
                            visible={createPresetModalIsOpen}
                            setVisible={() => {setCreatePresetModalIsOpen(true)}}
                        >
                            <CreateAnalyticsPresetModal onClose={() => {setCreatePresetModalIsOpen(false)}} />
                        </Modal>
                    )
                }

                <div className={styles.analyticsPage}>
                    <AdvancedAnalyticsPageHeader
                        options={analyticsOptions}
                        setOptions={(options) => { setAnalyticsOptions(options) }}
                        availableProducts={availableProducts}
                        availableCountries={availableCountries}
                        openCreatePresetModal={() => {setCreatePresetModalIsOpen(true)}}
                        onCreateReport={createReport}
                    />

                    <div className={styles.table}>
                        <h3>Report History</h3>
                    </div>

                    {
                        reports?.length
                            ? (
                                <>
                                    <AnalyticsReportHistoryTable
                                        reports={reports}
                                        chosenReports={chosenReports}
                                        setChooseOneReport={(report, value) => {
                                            value
                                                ? setChosenReports([...chosenReports, report])
                                                : setChosenReports(chosenReports.filter(r => r !== report))
                                        }}
                                        setChooseAllReports={(value) => {
                                            value
                                                ? setChosenReports(reports)
                                                : setChosenReports([]);
                                        }}
                                    />
                                    <TablePagination
                                        totalItems={totalItems}
                                        pageSize={pageSize}
                                        setPageSize={setPageSize}
                                        setCurrentPage={setCurrentPage}
                                        currentPage={currentPage}
                                    />
                                </>
                                )
                            : (
                                <NoDataComponent
                                    title={'No History Yet'}
                                    description={'All of your analytics creations will be stored here.'}
                                    isSmallHeight
                                />
                            )
                    }
                </div>
            </PageWrapper>
        </PageContainer>
    )
};

export default AdvancedAnalyticsPage;