import {BaseModalProps} from "../../../types/BaseModalProps/BaseModalProps";
import React, {FC, useState} from "react";
import styles from "../Modals.module.scss";
import bodyStyles from './ExtendSubscriptionModal.module.scss';
import Button, {ButtonStyles} from "../../UI/Button/Button";
import Radio from "../../UI/Radio/Radio";
import DatePicker, {DatePickerStyles} from "../../UI/DatePicker/DatePicker";
import {useAPI} from "../../../hooks/useAPI";
import {BoughtSubscriptionsService} from "../../../API/BoughtSubscriptionsService";
import {useAppContext} from "../../../hooks/useAppContext";

interface ExtendSubscriptionModalProps extends BaseModalProps {
    title: string;
    buttonText: string;
    onConfirm: () => void;
    subscriptionId: string;
}

const ExtendSubscriptionModal: FC<ExtendSubscriptionModalProps> = ({ onClose, title, buttonText, onConfirm, subscriptionId }) => {
    const [chosenVariant, setChosenVariant] = useState('0');
    const [endDate, setEndDate] = useState<Date | null>(null);

    const { shop } = useAppContext();

    const [, , extendSubscription] = useAPI(
        () => BoughtSubscriptionsService.extendSubscription(
            shop?.id || '',
            subscriptionId,
            endDate ? endDate.toISOString() : null,
        ),
        false,
        undefined,
        {
            onSuccess: onConfirm
        }
    );

    return (
        <div className={styles.modalInner}>
            <header className={styles.header}>
                <h1>{title}</h1>
            </header>

            <div className={bodyStyles.body}>
                <div className={bodyStyles.item} onClick={() => {setChosenVariant('0')}}>
                    <Radio id={'custom'} value={'0'} setValue={setChosenVariant} checked={chosenVariant === '0'} />
                    <div className={bodyStyles.ItemText}>
                        <span>On a custom date</span>
                        <p>Select a specific date to extend</p>
                    </div>
                    <div className={bodyStyles.ItemDate}>
                        <DatePicker minDate={new Date()} width={100} date={endDate ?? new Date()} setDate={setEndDate} />
                    </div>
                </div>

                <div className={bodyStyles.item} onClick={() => {
                    setChosenVariant('1')
                    setEndDate(null);
                }}>
                    <Radio id={'indefinite'} value={'1'} setValue={setChosenVariant} checked={chosenVariant === '1'} />
                    <div className={bodyStyles.ItemText}>
                        <span>Indefinite</span>
                        <p>The subscription will be infinite for the customer</p>
                    </div>
                </div>
            </div>

            <footer className={styles.footer}>
                <div className={styles.buttons} style={{
                    marginLeft: 'auto',
                    width: 'fit-content'
                }}>
                    <Button style={ButtonStyles.BORDERED} disableShadow={true} onClick={onClose}>
                        Cancel
                    </Button>
                    <Button
                        width={150}
                        style={ButtonStyles.PRIMARY}
                        onClick={() => {
                            extendSubscription();
                        }}
                    >
                        {buttonText}
                    </Button>
                </div>
            </footer>
        </div>
    )
};

export default ExtendSubscriptionModal;