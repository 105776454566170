import React, { useEffect, useState } from 'react'
import styles from './Header.module.scss'
import { SpriteIcon } from '../UI/SpriteIcon/SpriteIcon'
import ShopProfile from '../ShopProfile/ShopProfile'
import TeamMemberTag from '../ShopTeamMemberTag/ShopTeamMemberTag';
import { useAppContext } from '../../hooks/useAppContext'
import {useLocation, useNavigate, useParams} from 'react-router-dom'
import { getBreadcrumbs } from '../../helpers/getBreadcrumbs'
import { ShopForUserPermissions } from '../../types/ShopForUserPermissions/ShopForUserPermissions';
import {Breadcrumb, ConfigProvider} from "antd";

const Header = () => {
	const [searchValue, setSearchValue] = useState('')
	const { setIsSidebarActive, isSidebarActive, theme, setTheme, shop } = useAppContext()
	const [isNotificationsActive, setIsNotificationsActive] = useState(false)
	const navigate = useNavigate()
	const location = useLocation()
	const isMainPages = !(
		location.pathname.includes('auth') || location.pathname.includes('onboarding')
	)

	const commerceDropdownOptions = [
		{
			icon: {
				id: 'house',
				height: 13,
				width: 13,
			},
			onClick: () => navigate('/'),
			text: 'Dashboard',
		},
		{
			icon: {
				id: 'box',
				height: 13,
				width: 14,
			},
			onClick: () => navigate('/products'),
			text: 'Products',
		},
		{
			icon: {
				id: 'dollarCircle',
				height: 13,
				width: 13,
			},
			onClick: () => navigate('/payments'),
			text: 'Payments',
		},
		{
			icon: {
				id: 'person',
				height: 13,
				width: 13,
			},
			onClick: () => navigate('/customers'),
			text: 'Customers',
		},
		{
			icon: {
				id: 'messages',
				height: 13,
				width: 13,
			},
			onClick: () => navigate('/tickets'),
			text: 'Tickets',
		},
		{
			icon: {
				id: 'gear',
				height: 13,
				width: 13,
			},
			onClick: () => navigate('/settings'),
			text: 'Settings',
		},
	]

	const paymentsDropdownOptions = [
		{
			icon: {
				id: 'house',
				height: 13,
				width: 13,
			},
			onClick: () => window.open('https://payments.dashboard.sellpass.io/', '_self'),
			text: 'Dashboard',
		},
		{
			icon: {
				id: 'dollarCircle',
				height: 13,
				width: 13,
			},
			onClick: () => window.open('https://payments.dashboard.sellpass.io/payments', '_self'),
			text: 'Payments',
		},
		{
			icon: {
				id: 'person',
				height: 13,
				width: 13,
			},
			onClick: () => window.open('https://payments.dashboard.sellpass.io/customers', '_self'),
			text: 'Customers',
		},
		{
			icon: {
				id: 'gear',
				height: 13,
				width: 13,
			},
			onClick: () => window.open('https://payments.dashboard.sellpass.io/settings', '_self'),
			text: 'Settings',
		},
	]

	const builderDropdownOptions = [
		{
			icon: {
				id: 'builder',
				height: 13,
				width: 13,
			},
			onClick: () => window.open('https://builder.sellpass.io/', '_self'),
			text: 'Builder',
		},
		{
			icon: {
				id: 'plus',
				height: 13,
				width: 13,
			},
			onClick: () => window.open('https://builder.sellpass.io/add-block', '_self'),
			text: 'Add Block',
		},
		{
			icon: {
				id: 'gear',
				height: 13,
				width: 13,
			},
			onClick: () => window.open('https://builder.sellpass.io/settings', '_self'),
			text: 'Settings',
		},
	]

	const mobileDropdownOptions = [
		{
			icon: {
				id: 'box',
				height: 13,
				width: 13,
			},
			onClick: () => navigate('/'),
			text: 'Commerce',
		},
		{
			icon: {
				id: 'dollarCircle',
				height: 13,
				width: 13,
			},
			onClick: () => navigate('/payments'),
			text: 'Payments',
		},
		{
			icon: {
				id: 'builder',
				height: 13,
				width: 13,
			},
			onClick: () => navigate('/'),
			text: 'Builder',
		},
	]

	useEffect(() => {
		const savedTheme = localStorage.getItem('theme')

		if (savedTheme) {
			setTheme(savedTheme)
		}
	}, [])

	useEffect(() => {
		if (theme !== 'default') {
			window.document.documentElement.dataset.theme = theme
			localStorage.setItem('theme', theme)
		}
	}, [theme])

	const crumbs = getBreadcrumbs(location.pathname).map((crumb, index) => {
		return {
			title: index !== getBreadcrumbs(location.pathname).length - 1
				? <a
					onClick={() => {navigate('/' + location.pathname.split('/').slice(1, index + 2).join('/'))}}
					style={{ cursor: 'pointer' }}
				>{crumb}</a>
				: crumb
		}
	});

	return (
		<header className={styles.header} onMouseDown={event => event.stopPropagation()}>
			<div className={styles.headerStart}>
				{isMainPages && (
					<div className={styles.iconButton}>
						<div
							className={styles.burgerIcon}
							onClick={() => setIsSidebarActive(!isSidebarActive)}
							onMouseDown={event => event.stopPropagation()}
						>
							<span />
						</div>
					</div>
				)}
				<ConfigProvider
					theme={{
						components: {
							Breadcrumb: {
								lastItemColor: 'var(--dark_color)',
								linkColor: 'var(--secondary_text_color)',
								linkHoverColor: 'var(--dark_color)',
								separatorColor: 'var(--secondary_text_color)'
							},
						},
					}}
				>
					<Breadcrumb
						separator={ <SpriteIcon width={10} height={10} iconId={'arrow-right'} /> }
						items={[
							{
								title: 'Dashboard',
								href: '/'
							},
							...crumbs
						]}
					/>
				</ConfigProvider>

				{/*<div className={styles.breadcrumbs}>*/}
				{/*	<div className={styles.breadcrumbsItem}>*/}
				{/*		<p onClick={() => { navigate('/') }}>Dashboard</p>*/}
				{/*		<SpriteIcon*/}
				{/*			className={styles.breadcrumbsArrow}*/}
				{/*			iconId={'arrowDown2'}*/}
				{/*			width={10}*/}
				{/*			height={5}*/}
				{/*		/>*/}
				{/*	</div>*/}
				{/*	{getBreadcrumbs(location.pathname).map((item, index) => (*/}
				{/*		<div className={styles.breadcrumbsItem} key={item}>*/}
				{/*			<p onClick={() => {*/}
				{/*				const pagePath = '/' + location.pathname*/}
				{/*					.split('/')*/}
				{/*					.slice(1, index + 2)*/}
				{/*					.join('/');*/}

				{/*				navigate(pagePath);*/}
				{/*			}}>{item}</p>*/}
				{/*			<SpriteIcon*/}
				{/*				className={styles.breadcrumbsArrow}*/}
				{/*				iconId={'arrowDown2'}*/}
				{/*				width={10}*/}
				{/*				height={5}*/}
				{/*			/>*/}
				{/*		</div>*/}
				{/*	))}*/}
				{/*</div>*/}

				{/*<div className={styles.dropdowns}>*/}
				{/*	<div className={`${styles.dropdownWrapper} ${styles.mobile}`}>*/}
				{/*		<Dropdown options={mobileDropdownOptions} title={'Commerce'} style={'header'} />*/}
				{/*	</div>*/}
				{/*	<div className={styles.dropdownWrapper}>*/}
				{/*		<Dropdown options={commerceDropdownOptions} title={'Commerce'} style={'header'} />*/}
				{/*	</div>*/}
				{/*	<div className={styles.dropdownWrapper}>*/}
				{/*		<Dropdown options={paymentsDropdownOptions} title={'Payments'} style={'header'} />*/}
				{/*	</div>*/}
				{/*	<div className={styles.dropdownWrapper}>*/}
				{/*		<Dropdown options={builderDropdownOptions} title={'Builder'} style={'header'} />*/}
				{/*	</div>*/}
				{/*</div>*/}
			</div>

			<div className={styles.functionalHeader}>
				{!location.pathname.includes('auth') && (
					<div className={styles.headerEnd}>
						<div className={styles.iconButtons}>
							<div
								className={styles.iconButton}
								onClick={() => {
									if (theme === 'dark') setTheme('light')
									else setTheme('dark')
								}}
							>
								<SpriteIcon iconId={'moon2'} width={15} height={15} />
							</div>
							<div
								className={styles.iconButton}
								onClick={() =>
									window.open('https://support.billgang.com/en/', '_blank', 'noopener')
								}
							>
								<SpriteIcon iconId={'messageQuestion'} width={17} height={17} />
							</div>
							{/*<div*/}
							{/*	className={`${styles.notificationsWrapper} ${*/}
							{/*		isNotificationsActive && styles.active*/}
							{/*	}`}*/}
							{/*>*/}
							{/*	<div className={styles.iconButton} onClick={() => setIsNotificationsActive(true)}>*/}
							{/*		<SpriteIcon iconId={'bell'} width={14.5} height={14.5} />*/}
							{/*	</div>*/}
							{/*	<Notifications*/}
							{/*		isActive={isNotificationsActive}*/}
							{/*		setIsActive={setIsNotificationsActive}*/}
							{/*	/>*/}
							{/*</div>*/}
							<ShopProfile />
							{!shop?.permissions.includes(ShopForUserPermissions.OWNER) && <TeamMemberTag />}
						</div>
					</div>
				)}
			</div>
		</header>
	)
}

export default Header
