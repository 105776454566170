import React, { FC, ReactNode } from 'react';
import styles from './RedesignedTable.module.scss';
import { SpriteIcon } from '../UI/SpriteIcon/SpriteIcon';

interface HeaderItem {
    title?: string;
    iconId?: string;
    element?: React.ReactElement;
}

interface RedesignedTableProps {
    headers: HeaderItem[];
    rowsData: ReactNode[][];
    rowsHoverEffect?: boolean;
    hasCheckbox?: boolean;
}

const RedesignedTable: FC<RedesignedTableProps> = ({ headers, rowsData, rowsHoverEffect, hasCheckbox }) => {
    return (
        <div className={styles.tableWrapper}>
            <table className={styles.table}>
                <thead className={styles.thead}>
                <tr>
                    {headers.map((header, index) => (
                        <th key={index}>
                            {header.element ? (
                                header.element
                            ) : (
                                <div>
                                    <p>{header.title}</p>
                                    {header.iconId && (
                                        <SpriteIcon iconId={header.iconId} width={12} height={12}/>
                                    )}
                                </div>
                            )}
                        </th>
                    ))}
                </tr>
                </thead>
                <tbody className={`${styles.tbody} ${rowsHoverEffect && styles.withHover} ${hasCheckbox && styles.withCheckbox}`}>
                {rowsData.map((row, rowIndex) => (
                    <tr key={rowIndex}>
                        {row.map((cell, cellIndex) => (
                            <td key={cellIndex}>{cell}</td>
                        ))}
                    </tr>
                ))}
                </tbody>
            </table>
        </div>
    );
};

export default RedesignedTable;