const breadcrumbTemplates: { [key: string]: string[] } = {
	'/': ['Home'],
	'/products': ['Products'],
	'/products/warehouse': ['Products', 'Warehouse'],
	'/products/categories': ['Products', 'Categories'],
	'/products/groups': ['Products', 'Groups'],
	'/products/upsells': ['Products', 'Upsells'],
	'/products/addons': ['Products', 'Add-ons'],
	'/finances': ['Finances'],
	'/finances/orders': ['Finances', 'Orders'],
	'/finances/subscriptions': ['Finances', 'Subscriptions'],
	'/finances/subscriptions/:id': ['Finances', 'Subscriptions'],
	'/finances/charges': ['Finances', 'Charges'],
	'/finances/links': ['Finances', 'Links'],
	'/customers': ['Customers'],
	'/advanced-analytics': ['Advanced Analytics'],
	'/operations': ['Operations'],
	'/operations/tickets': ['Operations', 'Tickets'],
	'/operations/tickets/inbox': ['Operations', 'Tickets', 'Inbox'],
	'/operations/reviews': ['Operations', 'Reviews'],
	'/operations/blacklist': ['Operations', 'Blacklist'],
	'/operations/whitelist': ['Operations', 'Whitelist'],
	'/operations/images': ['Operations', 'Images'],
	'/website': ['Website'],
	'/website/posts': ['Website', 'Posts'],
	'/website/faq': ['Website', 'Questions'],
	'/marketing': ['Marketing'],
	'/marketing/coupons': ['Marketing', 'Coupons'],
	'/marketing/affiliates/rewards': ['Marketing', 'Affiliates', 'Rewards'],
	'/marketing/affiliates/members': ['Marketing', 'Affiliates', 'Members'],
	'/integrations': ['Integrations'],
	'/integrations/discord-bot': ['Integrations', 'Discord Bot'],
	'/integrations/app-store': ['Integrations', 'App Store'],
	'/integrations/custom-plugins': ['Integrations', 'Custom Plugins'],
	'/developers': ['Developers'],
	'/developers/api-keys': ['Developers', 'Keys'],
	'/developers/embeds': ['Developers', 'Embeds'],
	'/developers/webhooks': ['Developers', 'Webhooks'],
	'/developers/webhook-logs': ['Developers', 'Webhook Logs'],
	'/settings/general': ['Settings', 'General'],
	'/products/edit/:id': ['Products', 'Edit'],
	'/finances/orders/:id': ['Finances', 'Orders'],
	'/operations/reviews/:id': ['Operations', 'Reviews', 'View'],
	'/app-store/plugins': ['Apps', 'Plugins'],
	'/invite/:token/accept': ['Invites', 'Accept'],
	'/verifications/email': ['Verifications', 'Email'],
	'/products/categories/create': ['Products', 'Categories', 'Create'],
	'/products/create': ['Products', 'Create'],
	'/marketing/coupons/create': ['Marketing', 'Coupons', 'Create'],
	'/products/groups/create': ['Products', 'Groups', 'Create'],
	'/products/warehouse/stock-groups/:id': ['Products', 'Warehouse'],
	'/products/warehouse/stock-groups/edit/:id': ['Products', 'Warehouse', 'Edit'],
	'/customers/:id': ['Customers'],
	'/developers/webhook-logs/:externalMessageId/:externalEndpointId': ['Developers', 'Webhook Logs'],
	'/integrations/plugins/:id': ['Integrations', 'Custom Plugins'],
	'/integrations/discord-bot/builder': ['Integrations', 'Discord Bot', 'Builder'],
	'/integrations/telegram-bot': ['Integrations', 'Telegram Bot'],
	'/integrations/apps': ['Integrations', 'Apps'],
	'/website/posts/create': ['Website', 'Posts', 'Create'],
	'/website/posts/edit/:id': ['Website', 'Posts', 'Edit'],
	'/settings/team': ['Settings', 'Team'],
	'/settings/payments': ['Settings', 'Payments'],
	'/settings/integrations': ['Settings', 'Integrations'],
	'/settings/account': ['Settings', 'Account'],
	'/settings/legal': ['Settings', 'Legal'],
	'/settings/notifications': ['Settings', 'Notifications'],
	'/settings/audit-log': ['Settings', 'Audit Log'],
	'/settings/domains': ['Settings', 'Domains'],
	'/settings/billing': ['Settings', 'Billing'],
	'/settings/migrate': ['Settings', 'Migrate'],
}

export function getBreadcrumbs(path: string): string[] {
	for (const template in breadcrumbTemplates) {
		const pathRegex = new RegExp(
			'^' +
				template
					.replace(/:[^\s/]+/g, '([\\w-]+)') // Replace :id with a regex group
					.replace(/\//g, '\\/') + // Escape slashes
				'$'
		)

		const match = path.match(pathRegex)
		if (match) {
			return breadcrumbTemplates[template]
		}
	}
	return [] // Return an empty array if no match is found
}
