import { useState } from 'react';

export const usePagination = (initialPageSize = 5) => {
    const [totalItems, setTotalItems] = useState(0);
    const [currentPage, setCurrentPage] = useState(0);
    const [pageSize, setPageSize] = useState(initialPageSize);

    const updateTotalItems = (total: number): void => {
        setTotalItems(total);
    };

    const handlePageSizeChange = (newPageSize: number): void => {
        setPageSize(newPageSize);
        setCurrentPage(0);
    };

    return {
        totalItems,
        currentPage,
        pageSize,
        setPageSize: handlePageSizeChange,
        setCurrentPage,
        updateTotalItems,
    };
};