import {FC} from "react";

interface TruncatedTextProps {
    children: string;
    as: keyof JSX.IntrinsicElements;
    maxLength?: number;
    classname?: string;
}

const TruncatedText: FC<TruncatedTextProps> = ({ children, as, maxLength, classname }) => {
    const text: string = children;
    const Tag = as;

    const shouldBeTruncated: boolean = text.length > (maxLength ?? 13);
    const truncatedText: string = shouldBeTruncated? `${text.slice(0, maxLength ?? 13)}...` : text;

    return (
        // @ts-ignore
        <Tag
            className={classname}
            data-tooltip-id={shouldBeTruncated && 'my-tooltip'}
            data-tooltip-content={shouldBeTruncated && text}
        >
            {truncatedText}
        </Tag>
    );
};

export default TruncatedText;