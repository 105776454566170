import React, {FC} from 'react';
import styles from './SubscriptionsTable.module.scss';
import {SpriteIcon} from '../UI/SpriteIcon/SpriteIcon';
import {format} from 'date-fns';
import defaultAvatarMock from './defaultAvatarMock.png';
import SubscriptionsStatusBadge from '../UI/StatusBadges/SubscriptionsStatusBadge';
import {SubscriptionStatus} from '../../types/SubscriptionStatus/SubscriptionStatus';
import Checkbox from '../UI/Checkbox/Checkbox';
import {BoughtSubscription} from '../../types/BoughtSubscriptions/BoughtSubscriptions'
import {useNavigate} from "react-router-dom";
import RedesignedTable from "../RedesignedTable/RedesignedTable";
import {Dropdown, MenuProps} from "antd";
import DropdownMenuItem from "../UI/DropdownMenuItem/DropdownMenuItem";
import TableDropdown from "../UI/TableDropdown/TableDropdown";
import UserDefaultAvatar from "../UI/UserDefaultAvatar/UserDefaultAvatar";

interface SubscriptionsTableProps {
	subscriptions: BoughtSubscription[];
	chosenSubscriptions: BoughtSubscription[];
	chooseOneSubscription: (subscription: BoughtSubscription, value: boolean) => void;
	chooseAllSubscriptions: (value: boolean) => void;
	onBlockCustomer: (subscription: BoughtSubscription) => void;
	onCancelSubscription: (subscription: BoughtSubscription) => void;
	onExtendSubscription: (subscription: BoughtSubscription) => void;
	onSetExpirationDate: (subscription: BoughtSubscription) => void;
	onCopyCustomerId: (subscription: BoughtSubscription) => void;
}

const SubscriptionsTable: FC<SubscriptionsTableProps> = ({
	 subscriptions,
	 chosenSubscriptions,
	 chooseOneSubscription,
	 chooseAllSubscriptions,
	 onBlockCustomer,
	onCancelSubscription,
	onExtendSubscription,
	onSetExpirationDate,
	onCopyCustomerId
 }) => {
	// hooks
	const navigate = useNavigate();

	const openSubscriptionDetailsPage = (id: string): void => {
		navigate(`/finances/subscriptions/${id}`);
	}

	const checkIfEnoughSpaceUnder = (index: any): boolean => {
		return (index !== subscriptions.length - 2 && index !== subscriptions.length - 1)
	};

	const headerCheckbox = (
		<Checkbox
			id={'chooseAll'}
			checked={subscriptions.length > 0 && (chosenSubscriptions.length === subscriptions.length)}
			setChecked={(value) => chooseAllSubscriptions(value)}
		/>
	);

	const headers = [
		{ element: headerCheckbox },
		{ title: 'Subscription' },
		{ title: 'Discord Username' },
		{ title: 'Started' },
		{ title: 'Status' },
		{ title: '' },
	];

	const dropdownMenu = (subscription: BoughtSubscription): any => {
		const items: MenuProps['items'] = [
			{
				label: <DropdownMenuItem
							text={subscription.status === 'ACTIVE' ? 'Extend' : 'Activate & Extend'}
							iconId={'calendarExtend'}
						/>,
				key: '0',
				onClick: () => {onExtendSubscription(subscription)}
			},
			{
				label: <DropdownMenuItem
							text={'Set Expiration Date'}
							iconId={'calendarExpiration'}
						/>,
				disabled: subscription.status !== 'ACTIVE' && subscription.status !== 'PAST_DUE' && subscription.status !== 'CANCELLED',
				key: '1',
				onClick: () => {onSetExpirationDate(subscription)},
			},
			{
				label: <DropdownMenuItem
					text={'Copy Customer ID'}
					iconId={'copy-double'}
				/>,
				key: '2',
				onClick: () => {onCopyCustomerId(subscription)}
			},
			{
				type: 'divider',
			},
			{
				label: <DropdownMenuItem
					text={'Cancel Subscription'}
					iconId={'xMarkCircle'}
					textColor={!(subscription.status !== 'ACTIVE' && subscription.status !== 'PAST_DUE') ? 'var(--red)' : ''}
				/>,
				disabled: subscription.status !== 'ACTIVE' && subscription.status !== 'PAST_DUE',
				key: '3',
				onClick: () => {onCancelSubscription(subscription)},
			},
			{
				label: <DropdownMenuItem
					text={'Block Customer'}
					iconId={'delete-bin-red'}
					textColor={'var(--red)'}
				/>,
				key: '4',
				onClick: () => {onBlockCustomer(subscription)}
			},
		]

		return { items };
	};

	const rowsData = subscriptions.map((subscription, index) => [
		<Checkbox
			id={subscription.id}
			checked={chosenSubscriptions.includes(subscription)}
			setChecked={(value) => chooseOneSubscription(subscription, value)}
		/>,

		<div className={styles.subscriptionInfo} onClick={() => {openSubscriptionDetailsPage(subscription.id)}}>
			<div className={styles.subscriptionInfoImg}>
				<SpriteIcon className={styles.arrowRight} iconId={'emptyImg'} width={20} height={20}/>
			</div>
			<div className={styles.subscriptionInfoText}>
				<span>{subscription.product.name}</span>
				<p>{subscription.product.price.currency}{subscription.product.price.amount} / {subscription.product.variantName} {subscription.product.recurringInterval}</p>
			</div>
		</div>,

		<div className={styles.userInfo} onClick={() => {openSubscriptionDetailsPage(subscription.id)}}>
			<UserDefaultAvatar
				text={subscription.customer.discordUsername ?? subscription.customer.email}
				width={24}
				height={24} />
			<span
				className={styles.userInfoName}>{subscription.customer.discordUsername ?? subscription.customer.email}</span>
		</div>,

		<div className={styles.startDate} onClick={() => {openSubscriptionDetailsPage(subscription.id)}}>
			<span>{format(new Date(subscription.createdAt), 'MMM dd, yyyy')}</span>
			<p>at {format(new Date(subscription.createdAt), 'hh:mm aa z')}</p>
		</div>,

		<div className={styles.status} onClick={() => {openSubscriptionDetailsPage(subscription.id)}}>
			{/* @ts-ignore */}
			<SubscriptionsStatusBadge subscriptionStatus={SubscriptionStatus[subscription.status]}/>
		</div>,

		<TableDropdown menuItems={dropdownMenu(subscription)} />
	]);

	return <RedesignedTable headers={headers} rowsData={rowsData} rowsHoverEffect />;
};

export default SubscriptionsTable;