import { privateAPI } from "./API";
import {CreateReportRequestData} from "../types/AnalyticsReports/AnalyticsReports";

export const ReportsService = {
    getReports: async (shopId: string) => {
        return await privateAPI.get(`/v1/dash/shops/${shopId}/reports`);
    },

    getReport: async (shopId: string, reportId: string) => {
        return await privateAPI.get(`/v1/dash/shops/${shopId}/reports/${reportId}`);
    },

    createReport: async (shopId: string, data: CreateReportRequestData) => {
        return await privateAPI.post(`/v1/dash/shops/${shopId}/reports`, data);
    }
};