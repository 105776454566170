import React, {FC} from "react";
import styles from './DropdownMenuItem.module.scss';
import {SpriteIcon} from "../SpriteIcon/SpriteIcon";

interface DropdownMenuItemProps {
    text: string;
    iconId?: string;
    textColor?: string;
}

const DropdownMenuItem: FC<DropdownMenuItemProps> = ({ text, iconId, textColor }) => (
    <div className={styles.dropdownItem}>
        { iconId && <SpriteIcon iconId={iconId} width={20} height={20}/> }
        <span style={{ color: textColor }}>{text}</span>
    </div>
);

export default DropdownMenuItem;