import React, { FC } from 'react'
import styles from './PaymentTypeBadge.module.scss'
import { SpriteIcon } from '../UI/SpriteIcon/SpriteIcon'
import { Gateways } from '../../types/Gateways/Gateways'
import { getGatewaysOptions } from '../../helpers/getGatewaysOptions'
import { useAppContext } from '../../hooks/useAppContext'
import {he} from "date-fns/locale";

interface PaymentTypeBadgeProps {
	gateway: string;
	isTextHidden?: boolean;
	width?: number;
	height?: number;
}

const PaymentTypeBadge: FC<PaymentTypeBadgeProps> = ({ gateway, isTextHidden, width, height }) => {
	const { availablePayments } = useAppContext()
	const gatewayOptions = availablePayments?.find(item => item.name === gateway)

	return (
		<div className={styles.paymentTypeBadge} style={{ width: width ?? '', height: height ?? '' }}>
			<div className={styles.logo}>
				<img
					src={`https://imagedelivery.net/${gatewayOptions?.logoCfImageId}/productCard`}
					alt={gatewayOptions?.name}
				/>
			</div>
			{!isTextHidden && gatewayOptions?.displayName}
		</div>
	)
}

export default PaymentTypeBadge
