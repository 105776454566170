import React, { FC } from 'react'
import styles from './StatusBadges.module.scss'
import { SpriteIcon } from '../SpriteIcon/SpriteIcon'
import { SubscriptionStatus } from '../../../types/SubscriptionStatus/SubscriptionStatus'

interface SubscriptionsStatusBadgeProps {
	subscriptionStatus: SubscriptionStatus
}

const SubscriptionsStatusBadge: FC<SubscriptionsStatusBadgeProps> = ({ subscriptionStatus }) => {
	const subscriptionStatusData = {
		[SubscriptionStatus.EXPIRED]: {
			name: 'Expired',
			className: 'red',
			iconId: 'xMarkCircle',
		},
		[SubscriptionStatus.CANCELLED]: {
			name: 'Cancelled',
			className: 'red',
			iconId: 'xMarkCircle',
		},
		[SubscriptionStatus.ACTIVE]: {
			name: 'Active',
			className: 'green',
			iconId: 'checkedCircle',
		},
		[SubscriptionStatus.TRIAL]: {
			name: 'Trial',
			className: 'yellow',
			iconId: 'schedule',
		},
		[SubscriptionStatus.AWAITING_PAYMENT]: {
			name: 'Awaiting payment',
			className: 'yellow',
			iconId: 'awaiting',
		},
		[SubscriptionStatus.PAST_DUE]: {
			name: 'Past due',
			className: 'red',
			iconId: 'xMarkCircle',
		},
		[SubscriptionStatus.CHANGED_GATEWAY]: {
			name: 'Changed gateway',
			className: 'red',
			iconId: 'xMarkCircle',
		},
		[SubscriptionStatus.REPLACED]: {
			name: 'Replaced',
			className: 'red',
			iconId: 'xMarkCircle',
		},
	}

	return (
		<div
			className={`${styles.statusBadge} ${styles.subscriptionStatusBadge} ${styles[subscriptionStatusData[subscriptionStatus].className]}`}
			data-tooltip-id={'my-tooltip'}
			data-tooltip-content={`Status: ${subscriptionStatusData[subscriptionStatus].name}`}
		>
			<SpriteIcon iconId={subscriptionStatusData[subscriptionStatus].iconId} width={16} height={16} />
			{subscriptionStatusData[subscriptionStatus].name}
		</div>
	)
}

export default SubscriptionsStatusBadge
