import React, { FC, ReactNode } from 'react'
import styles from './Button.module.scss'
import Spinner from '../Spinner/Spinner'
import { SpriteIcon } from '../SpriteIcon/SpriteIcon'

interface ButtonProps {
	icon?: {
		id: string
		align: ButtonsIconAlign
		width: number
		height: number
		className?: string
	}
	children?: ReactNode
	style?: ButtonStyles
	size?: 'small' | 'large'
	onClick?: (event: React.MouseEvent<HTMLButtonElement>) => void
	isLoading?: boolean
	isDisabled?: boolean
	width?: string | number
	height?: string | number
	disableShadow?: boolean
	customClassname?: string;
	isHidden?: boolean
}

export enum ButtonStyles {
	PRIMARY = 'primary',
	SECONDARY = 'secondary',
	THIRD = 'third',
	TRANSPARENT = 'transparent',
	DANGER = 'danger',
	DARK = 'dark',
	BORDERED = 'bordered',
	LIGHT = 'light',
}

export enum ButtonsIconAlign {
	RIGHT = 'iconRight',
	LEFT = 'iconLeft',
}

const Button: FC<ButtonProps> = ({
	style,
	icon,
	children,
	size,
	onClick,
	isLoading,
	isDisabled,
	isHidden,
	width,
	height,
	disableShadow,
	customClassname
}) => {
	return (
		<button
			onClick={event => {
				event.stopPropagation()
				onClick && onClick(event)
			}}
			disabled={isDisabled || isLoading}
			className={`${styles.button} ${style && styles[style]} ${size && styles[size]} ${
				icon && styles[icon.align]
			} ${isLoading && styles['iconRight']} ${disableShadow && styles.disableShadow} ${customClassname && customClassname}`}
			style={{
				width,
				height,
				display: isHidden ? 'none' : ''
			}}
		>
			{isLoading ? (
				<Spinner className={styles.spinner} />
			) : (
				icon && (
					<SpriteIcon
						className={`${icon?.className || styles.icon}`}
						iconId={icon?.id}
						width={icon.width}
						height={icon.height}
					/>
				)
			)}
			{children && <p className={`${styles.text} ${!icon && styles.ml0}`}>{children}</p>}
		</button>
	)
}

export default Button
