import React, { FC, useEffect, useState } from 'react'
import PageContainer from '../../components/UI/PageContainer/PageContainer'
import PageWrapper from '../../components/PageWrapper/PageWrapper'
import styles from './SubscriptionsPage.module.scss'
import PageHeader from '../../components/PageHeader/PageHeader'
import SubscriptionsPageActions from './SubscriptionsPageActions/SubscriptionsPageActions'
import NoDataComponent from '../../components/NoDataComponent/NoDataComponent'
import noOrdersImage from '../../assets/images/noOrders.png'
import { BoughtSubscription } from '../../types/BoughtSubscriptions/BoughtSubscriptions'
import SubscriptionsTable from '../../components/Tables/SubscriptionsTable'
import Pagination from '../../components/UI/Pagination/Pagination'
import { useAPI } from '../../hooks/useAPI'
import { BoughtSubscriptionsService } from '../../API/BoughtSubscriptionsService'
import { useAppContext } from '../../hooks/useAppContext'
import { useFirstLoading } from '../../hooks/useFirstLoading'
import PageLoading from '../../components/UI/PageLoading/PageLoading'
import Spinner from '../../components/UI/Spinner/Spinner'
import Button, {ButtonsIconAlign} from "../../components/UI/Button/Button";
import Modal from "../../components/Modals/Modal";
import ConfirmModal from "../../components/Modals/ConfirmModal/ConfirmModal";
import SubscriptionsPageSubscriptionDetails from "./SubscriptionsPageSubscriptionDetails/SubscriptionsPageSubscriptionDetails";
import {useParams} from "react-router-dom";
import TablePagination from "../../components/TablePagination/TablePagination";
import {usePagination} from "../../hooks/usePagination";
import ExtendSubscriptionModal from "../../components/Modals/ExtendSubscriptionModal/ExtendSubscriptionModal";
import SetSubscriptionExpirationModal
	from "../../components/Modals/SetSubscriptionExpirationModal/SetSubscriptionExpirationModal";
import setSubscriptionExpirationModal
	from "../../components/Modals/SetSubscriptionExpirationModal/SetSubscriptionExpirationModal";
import {useAfterMountEffect} from "../../hooks/useAfterMountEffect";

const SubscriptionsPage: FC = () => {
	const [subscriptionSearchValue, setSubscriptionSearchValue] = useState<string>('');
	const [subscriptionFilterValue, setSubscriptionFilterValue] = useState('All Subscriptions');
	const [chosenSubscriptions, setChosenSubscriptions] = useState<BoughtSubscription[]>([]);

	const [chosenSubscriptionForAction, setChosenSubscriptionForAction] = useState<BoughtSubscription>();
	const [expirationDate, setExpirationDate] = useState<string | null>('');

	const [modalCancelIsOpened, setModalCancelIsOpened] = useState(false);
	const [modalBlockIsOpened, setModalBlockIsOpened] = useState(false);
	const [modalExtendIsOpened, setModalExtendIsOpened] = useState(false);
	const [modalExpirationIsOpened, setModalExpirationIsOpened] = useState(false);

	const {
		totalItems,
		currentPage,
		pageSize,
		setPageSize,
		setCurrentPage,
		updateTotalItems
	} = usePagination();
	const { shop, setActionMessage } = useAppContext();
	const { subscriptionId } = useParams();

	const [getSubscriptionsLoading, subscriptions, getSubscriptions] = useAPI<BoughtSubscription[]>(
		() => BoughtSubscriptionsService.getSubscriptions(
			shop?.id || '',
			subscriptionSearchValue,
			subscriptionFilterValue === 'All Subscriptions' ? '' : subscriptionFilterValue,
			currentPage + 1,
			pageSize,
			),
		false,
		undefined,
		{
			onSuccess: response => {
				updateTotalItems(response.headers['x-pagination-total'])
			},
		}
	);

	const [, , cancelSubscription] = useAPI(
		() => BoughtSubscriptionsService.cancelSubscription(
			shop?.id || '',
            chosenSubscriptionForAction?.id || ''
		),
		false,
		undefined,
		{
			onSuccess: () => {
				setModalCancelIsOpened(false);
                getSubscriptions();
			}
		}
	);

	useEffect(() => {
		shop && !subscriptionId && getSubscriptions();
	}, [shop, currentPage, pageSize, subscriptionFilterValue])

	useAfterMountEffect(() => {
		if (!subscriptionSearchValue) {
			shop && getSubscriptions();
		} else {
			const timeout = setTimeout(() => {
				shop && getSubscriptions();
			}, 1000);

			return () => {
				clearTimeout(timeout);
			};
		}
	}, [subscriptionSearchValue]);

	useAfterMountEffect(() => {
		shop && !subscriptionId && !subscriptions && getSubscriptions();
	}, [subscriptionId])

	const isFirstLoading = useFirstLoading(getSubscriptionsLoading);

	const addChosenSubscription = (subscription: BoughtSubscription): void => {
		setChosenSubscriptions((prev) => [...prev, subscription]);
	}

	const addAllSubscriptions = (): void => {
		setChosenSubscriptions(subscriptions ?? []);
	}

	if (subscriptionId) {
		return <SubscriptionsPageSubscriptionDetails subscriptionId={subscriptionId}/>
	}

	if (isFirstLoading) {
		return <PageLoading />
	}

	if (!subscriptions?.length && !subscriptionSearchValue && subscriptionFilterValue === 'All Subscriptions') {
		return (
			<NoDataComponent
				imageSrc={noOrdersImage}
				title={'No Subscriptions'}
				description={'When someone buys a subscription, it will appear here.'}
			/>
		)
	}

	return (
		<PageContainer>
			<PageWrapper>
				<div className={styles.subscriptionsPage}>
					{
						modalBlockIsOpened && (
							<Modal closeModal={() => {setModalBlockIsOpened(false)}} visible={modalBlockIsOpened} setVisible={() => {setModalBlockIsOpened(true)}}>
								<ConfirmModal
									title={'Block customer'}
									description={`You’re about to block ${chosenSubscriptionForAction?.customer.discordUsername}. Are you sure you want to block this member? Members on your team can unblock them at any time in the future.`}
									onConfirm={() => {}}
									confirmButtonText={'Block'}
									onClose={() => {setModalBlockIsOpened(false)}}
								/>
							</Modal>
						)
					}

					{
						modalCancelIsOpened && (
							<Modal closeModal={() => {setModalCancelIsOpened(false)}} visible={modalCancelIsOpened} setVisible={() => {setModalCancelIsOpened(true)}}>
								<ConfirmModal
									title={'Cancel Subscription'}
									description={`You’re about to cancel ${chosenSubscriptionForAction?.customer.discordUsername} ‘s subscription. Are you sure you want to do this?`}
									onConfirm={() => {
										cancelSubscription();
									}}
									confirmButtonText={'Cancel Subscription'}
									onClose={() => {
										setModalCancelIsOpened(false);
									}}
								/>
							</Modal>
						)
					}

					{
						modalExtendIsOpened && (
							<Modal closeModal={() => {setModalExtendIsOpened(false)}} visible={modalExtendIsOpened} setVisible={() => {setModalExtendIsOpened(true)}}>
								<ExtendSubscriptionModal
									title={chosenSubscriptionForAction?.status === 'ACTIVE' ? 'Extend' : 'Activate & Extend' + ' Subscription'}
									onClose={() => {setModalExtendIsOpened(false)}}
									buttonText={chosenSubscriptionForAction?.status === 'ACTIVE' ? 'Extend' : ' Activate & Extend'}
									subscriptionId={chosenSubscriptionForAction?.id || ''}
									onConfirm={() => {
										setModalExtendIsOpened(false);
										getSubscriptions();
									}}
								/>
							</Modal>
						)
					}

					{
						modalExpirationIsOpened && (
							<Modal closeModal={() => {setModalExpirationIsOpened(false)}} visible={modalExpirationIsOpened} setVisible={() => {setModalExpirationIsOpened(true)}} >
								<SetSubscriptionExpirationModal
									onClose={() => {setModalExpirationIsOpened(false)}}
									subscriptionId={chosenSubscriptionForAction?.id || ''}
									onConfirm={() => {
										setModalExpirationIsOpened(false);
										getSubscriptions();
									}}
								/>
							</Modal>
						)
					}

					<PageHeader title={'Subscriptions'} isRow >
						<Button height={32} icon={
							{
								id: 'addNewBlack',
								width: 15,
								height: 15,
								align: ButtonsIconAlign.LEFT,
							}
						}>Create New Subscription</Button>
					</PageHeader>

					<SubscriptionsPageActions searchValue={subscriptionSearchValue} setSearchValue={
						(value: string) => {
							setSubscriptionSearchValue(value);
						}} filterValue={subscriptionFilterValue} setFilterValue={(value) => {
						setSubscriptionFilterValue(value)
					}} />

					{getSubscriptionsLoading ? (
						<Spinner />
					) : (
						subscriptions && (
							<>
								<SubscriptionsTable
									subscriptions={subscriptions}
									chosenSubscriptions={chosenSubscriptions}
									chooseOneSubscription={(subscription, value) => {
										value && addChosenSubscription(subscription);
										!value && setChosenSubscriptions(chosenSubscriptions.filter((item) => item.id !== subscription.id));
									}}
									chooseAllSubscriptions={(value) => {
										value && addAllSubscriptions();
										!value && setChosenSubscriptions([]);
									}}
									onBlockCustomer={(sub) => {
										setChosenSubscriptionForAction(sub);
										setModalBlockIsOpened(true);
									}}
									onCancelSubscription={(sub) => {
										setChosenSubscriptionForAction(sub);
										setModalCancelIsOpened(true);
									}}
									onExtendSubscription={(sub) => {
										setChosenSubscriptionForAction(sub);
										setModalExtendIsOpened(true);
									}}
									onSetExpirationDate={(sub) => {
										setChosenSubscriptionForAction(sub);
										setModalExpirationIsOpened(true);
									}}
									onCopyCustomerId={(sub) => {
										if (sub?.customer.id) {
											window.navigator.clipboard.writeText(sub.customer.id).then(() => {
												setActionMessage({
													status: true,
													isActive: true,
													message: 'Copied customer ID',
													title: 'Copy customer ID',
												})
											});
										} else {
											setActionMessage({
												status: false,
												isActive: true,
												message: 'Failed to copy customer ID',
												title: 'Copy customer ID',
											})
										}
									}}
								/>

								<TablePagination
									totalItems={totalItems}
									pageSize={pageSize}
									setPageSize={setPageSize}
									setCurrentPage={setCurrentPage}
									currentPage={currentPage}
								/>
							</>
						)
					)}
				</div>
			</PageWrapper>
		</PageContainer>
	)
}

export default SubscriptionsPage