import React, {FC, useEffect, useState} from "react";
import styles from './SubscriptionsPageSubscriptionDetails.module.scss'
import PageContainer from "../../../components/UI/PageContainer/PageContainer";
import PageWrapper from "../../../components/PageWrapper/PageWrapper";
import PageHeader from "../../../components/PageHeader/PageHeader";
import {useNavigate} from "react-router-dom";
import Button, {ButtonsIconAlign, ButtonStyles} from "../../../components/UI/Button/Button";
import defaultAvatarMock from "../../../components/Tables/defaultAvatarMock.png";
import {SpriteIcon} from "../../../components/UI/SpriteIcon/SpriteIcon";
import SubscriptionsStatusBadge from "../../../components/UI/StatusBadges/SubscriptionsStatusBadge";
import {SubscriptionStatus} from "../../../types/SubscriptionStatus/SubscriptionStatus";
import {useAPI} from "../../../hooks/useAPI";
import {useAppContext} from "../../../hooks/useAppContext";
import {BoughtSubscriptionsService} from "../../../API/BoughtSubscriptionsService";
import PageLoading from "../../../components/UI/PageLoading/PageLoading";
import {BoughtSubscriptionDetails} from "../../../types/BoughtSubscriptions/BoughtSubscriptions";
import OrdersHistoryTable from "../../../components/Tables/OrdersHistoryTable/OrdersHistoryTable";
import TablePagination from "../../../components/TablePagination/TablePagination";
import {usePagination} from "../../../hooks/usePagination";
import Modal from "../../../components/Modals/Modal";
import ConfirmModal from "../../../components/Modals/ConfirmModal/ConfirmModal";
import ExtendSubscriptionModal from "../../../components/Modals/ExtendSubscriptionModal/ExtendSubscriptionModal";
import SetSubscriptionExpirationModal
    from "../../../components/Modals/SetSubscriptionExpirationModal/SetSubscriptionExpirationModal";
import Dropdown from "../../../components/UI/Dropdown/Dropdown";
import {OrdersService} from "../../../API/OrdersService";
import NoDataComponent from "../../../components/NoDataComponent/NoDataComponent";
import noOrdersImage from "../../../assets/images/noOrders.png";
import {Order} from "../../../types/Order/Order";
import {OrderStatus} from "../../../types/OrderStatus/OrderStatus";
import Spinner from "../../../components/UI/Spinner/Spinner";
import pageNotFoundImage from "../../../assets/images/404.png";
import PaymentTypeBadge from "../../../components/PaymentTypeBadge/PaymentTypeBadge";
import UserDefaultAvatar from "../../../components/UI/UserDefaultAvatar/UserDefaultAvatar";

interface SubscriptionsPageSubscriptionDetailsProps {
    subscriptionId: string;
}

const SubscriptionsPageSubscriptionDetails: FC<SubscriptionsPageSubscriptionDetailsProps> = ({subscriptionId}) => {
    const navigate = useNavigate();
    const { shop, setActionMessage } = useAppContext();
    const {
        totalItems,
        currentPage,
        pageSize,
        setPageSize,
        setCurrentPage,
        updateTotalItems
    } = usePagination();

    const [ordersHistoryListIsActive, setOrdersHistoryListIsActive] = useState(false);
    const [filterBy, setFilterBy] = useState<OrderStatus | null>(null);

    const [modalCancelIsOpened, setModalCancelIsOpened] = useState(false);
    const [modalBlockIsOpened, setModalBlockIsOpened] = useState(false);
    const [modalExtendIsOpened, setModalExtendIsOpened] = useState(false);
    const [modalExpirationIsOpened, setModalExpirationIsOpened] = useState(false);
    const [error404, setError404] = useState(false);

    const [expirationDate, setExpirationDate] = useState<string | null>(null);

    const [isLoading, subscription, getSubscriptionDetails] = useAPI<BoughtSubscriptionDetails>(
        () => BoughtSubscriptionsService.getSubscriptionDetails(shop?.id || '', subscriptionId),
        false,
        undefined,
        {
            onError: (error) => {
                error.response?.status === 404 && shop && setError404(true);
            }
        }
    );

    const transformCurrencySign = (currency: string): string => {
        switch (currency) {
            case 'USD':
                return '$';
            case 'EUR':
                return '€';
            default:
                return '$';
        }
    };

    const transformRecurringInterval = (variant: string): string => {
        switch (variant) {
            case 'Yearly':
                return 'Year'
            case 'Monthly':
                return 'Month'
            default:
                return 'Week'
        }
    }

    const formatDateTime = (dateString: string): Record<string, string> => {
        const dateObj = new Date(dateString);

        const formattedDate = dateObj.toLocaleDateString('en-US', { year: 'numeric', month: 'short', day: 'numeric' });
        const formattedTime = dateObj.toLocaleTimeString('en-US', { hour: 'numeric', minute: 'numeric', hour12: true, timeZoneName: 'short' });

        return {
            date: formattedDate,
            time: formattedTime
        };
    }

    const transformDataObjectIntoArray = (data: Record<string, string>): Record<string, string>[] => {
        const transformedArray: Record<string, string>[] = [];
        Object.keys(data).forEach((key: keyof typeof data) => {
            transformedArray.push({
                key: key,
                value: data[key]
            })
        });
        return transformedArray;
    };

    const findAndFormatPaymentDate = (data: Record<string, string>): any => {
        let paymentDate = '';

        Object.keys(data).reverse().forEach((key) => {
            if (data[key] === 'ACTIVE' || !paymentDate) paymentDate = key;
        })

        if (!paymentDate) {
            return null;
        }

        const dateObj = new Date(paymentDate);
        const formattedDate = dateObj.toLocaleDateString('en-US', { year: 'numeric', month: 'short', day: 'numeric' });
        const formattedTime = dateObj.toLocaleTimeString('en-US', { hour: 'numeric', minute: 'numeric', hour12: true, timeZoneName: 'short' });

        return {
            date: formattedDate,
            time: formattedTime
        };
    };

    const [ordersLoading, orders, getOrders] = useAPI<Order[]>(
        () => OrdersService.getAll(
            shop?.id || '',
            filterBy,
            '',
            currentPage + 1,
            pageSize,
            subscriptionId,
        ),
        false,
        undefined,
        {
            onSuccess: (response) => {
                updateTotalItems(response.headers['x-pagination-total']);
            }
        }
    );

    const [, , cancelSubscription] = useAPI(
        () => BoughtSubscriptionsService.cancelSubscription(
            shop?.id || '',
            subscriptionId || ''
        ),
        false,
        undefined,
        {
            onSuccess: () => {
                setModalCancelIsOpened(false);
                window.location.reload();
            }
        }
    );

    const [, , extendSubscription] = useAPI(
        () => BoughtSubscriptionsService.extendSubscription(
            shop?.id || '',
            subscriptionId,
            expirationDate
        ),
        false,
        undefined,
        {
            onSuccess: () => {
                setModalExtendIsOpened(false);
            }
        }
    );

    const [, , expireSubscription] = useAPI(
        () => BoughtSubscriptionsService.expireSubscription(
            shop?.id || '',
            subscriptionId || '',
            expirationDate
        ),
        false,
        undefined,
        {
            onSuccess: () => {
                setModalExpirationIsOpened(false);
            },
        }
    );

    useEffect(() => {
        shop && getSubscriptionDetails();
    }, [subscriptionId, shop]);

    useEffect(() => {
        shop && getOrders();
    }, [shop, currentPage, pageSize, filterBy]);

    if (error404 && shop?.id) {
        return (
            <NoDataComponent
                title={'Subscription Not Found'}
                description={
                    'The Subscription you are looking for may have been removed.'
                }
                imageSrc={pageNotFoundImage}
                button={{
                    text: 'Check Subscriptions',
                    onClick: () => navigate('/finances/subscriptions'),
                }}
            />
        );
    }

    if (isLoading || !subscription) {
        return <PageLoading />;
    }

    return (
        <PageContainer>
            <PageWrapper>
                <div className={styles.subscriptionDetails}>
                    {
                        modalBlockIsOpened && (
                            <Modal closeModal={() => {setModalBlockIsOpened(false)}} visible={modalBlockIsOpened} setVisible={() => {setModalBlockIsOpened(true)}}>
                                <ConfirmModal
                                    title={'Block customer'}
                                    description={`You’re about to block ${subscription?.customerInfo.discordSocialConnect?.username}. Are you sure you want to block this member? Members on your team can unblock them at any time in the future.`}
                                    onConfirm={() => {}}
                                    confirmButtonText={'Block'}
                                    onClose={() => {setModalBlockIsOpened(false)}}
                                />
                            </Modal>
                        )
                    }

                    {
                        modalCancelIsOpened && (
                            <Modal closeModal={() => {setModalCancelIsOpened(false)}} visible={modalCancelIsOpened} setVisible={() => {setModalCancelIsOpened(true)}}>
                                <ConfirmModal
                                    title={'Cancel Subscription'}
                                    description={`You’re about to cancel ${subscription?.customerInfo.discordSocialConnect?.username} ‘s subscription. Are you sure you want to do this?`}
                                    onConfirm={() => {
                                        cancelSubscription();
                                    }}
                                    confirmButtonText={'Cancel'}
                                    onClose={() => {setModalCancelIsOpened(false)}}
                                />
                            </Modal>
                        )
                    }

                    {
                        modalExtendIsOpened && (
                            <Modal closeModal={() => {setModalExtendIsOpened(false)}} visible={modalExtendIsOpened} setVisible={() => {setModalExtendIsOpened(true)}}>
                                <ExtendSubscriptionModal
                                    title={subscription?.status === 'ACTIVE' ? 'Extend' : 'Activate & Extend' + ' Subscription'}
                                    onClose={() => {setModalExtendIsOpened(false)}}
                                    buttonText={subscription?.status === 'ACTIVE' ? 'Extend' : ' Activate & Extend'}
                                    subscriptionId={subscriptionId}
                                    onConfirm={() => {
                                        setModalExtendIsOpened(false);
                                        window.location.reload();
                                    }}
                                />
                            </Modal>
                        )
                    }

                    {
                        modalExpirationIsOpened && (
                            <Modal closeModal={() => {setModalExpirationIsOpened(false)}} visible={modalExpirationIsOpened} setVisible={() => {setModalExpirationIsOpened(true)}} >
                                <SetSubscriptionExpirationModal
                                    onClose={() => {setModalExpirationIsOpened(false)}}
                                    subscriptionId={subscriptionId}
                                    onConfirm={() => {
                                        setModalExpirationIsOpened(false);
                                        window.location.reload();
                                    }}
                                />
                            </Modal>
                        )
                    }

                    <PageHeader backButton={{
                        isActive: true,
                        onClick: () => navigate(-1)
                    }} title={`Subscription #${subscriptionId}`} isRow>
                        <div className={styles.headerButtons}>
                            <Button
                                height={32}
                                icon={{
                                id: 'calendarExtend',
                                width: 20,
                                height: 20,
                                align: ButtonsIconAlign.LEFT
                                }}
                                onClick={() => {setModalExtendIsOpened(true)}}
                            >
                                {subscription?.status === 'ACTIVE' ? 'Extend' : ' Activate & Extend'}
                            </Button>

                            <Button
                                height={32}
                                icon={{
                                    id: 'calendarExpiration',
                                    width: 20,
                                    height: 20,
                                    align: ButtonsIconAlign.LEFT
                                }}
                                onClick={() => {setModalExpirationIsOpened(true)}}
                                isHidden={subscription.status !== 'ACTIVE' && subscription.status !== 'PAST_DUE' && subscription.status !== 'CANCELLED'}
                            >
                                Set Expiration Date
                            </Button>
                            <Button
                                height={32}
                                icon={{
                                    id: 'copy-double',
                                    width: 20,
                                    height: 20,
                                    align: ButtonsIconAlign.LEFT
                                }}
                                onClick={() => {
                                    if (subscription?.customerInfo.id) {
                                        window.navigator.clipboard.writeText(subscription.customerInfo.id.toString()).then(() => {
                                            setActionMessage({
                                                status: true,
                                                isActive: true,
                                                message: 'Copied customer ID',
                                                title: 'Copy customer ID',
                                            })
                                        });
                                    } else {
                                        setActionMessage({
                                            status: false,
                                            isActive: true,
                                            message: 'Failed to copy customer ID',
                                            title: 'Copy customer ID',
                                        })
                                    }
                                }}
                            />
                            <Dropdown
                                options={[
                                    {
                                        text: 'Cancel Subscription',
                                        icon: {
                                            id: 'xMarkRed',
                                            width: 20,
                                            height: 20,
                                        },
                                        onClick: () => {setModalCancelIsOpened(true)},
                                        isHidden: subscription.status !== 'ACTIVE' && subscription.status !== 'PAST_DUE'
                                    },
                                    {
                                        text: 'Block Customer',
                                        icon: {
                                            id: 'delete-bin-red',
                                            width: 20,
                                            height: 20,
                                        },
                                        onClick: () => {setModalBlockIsOpened(true)}
                                    },
                                ]}
                                width={200}
                                right={0}
                                top={36}
                                iconId={'ellipsis'}
                                iconWidth={18}
                                iconHeight={18}
                                customFaceStyle={styles.customDropdownFace}
                            />
                        </div>
                    </PageHeader>

                    <div className={styles.body}>
                        <div className={styles.info}>
                            <div className={styles.userInfo}>
                                <div className={styles.userInfoName}>
                                    <UserDefaultAvatar
                                        text={
                                            subscription?.customerInfo.discordSocialConnect?.username
                                                ??
                                            subscription?.customerInfo.email
                                        }
                                        width={32}
                                        height={32}
                                        backgroundColor={'var(--light_color)'}
                                    />
                                    <div>
                                        <span>{subscription?.customerInfo.discordSocialConnect?.username}</span>
                                        <p>{subscription?.customerInfo.email}</p>
                                    </div>
                                    <Button icon={{
                                        id: 'export',
                                        width: 20,
                                        height: 20,
                                        align: ButtonsIconAlign.LEFT
                                    }}
                                    onClick={() => { navigate(`/customers/${subscription?.customerInfo.id}`) }}
                                    />
                                </div>

                                <div className={styles.userInfoSubData}>
                                    <span>
                                        Subscription Data
                                    </span>

                                    <div>
                                        <div className={styles.userInfoSubDataItem}>
                                            <div className={styles.userInfoSubDataItemIcon}>
                                                <PaymentTypeBadge isTextHidden={true} gateway={subscription?.preferredGateway} />
                                            </div>
                                            <div>
                                                <p>Preferred Payment</p>
                                                <span>{subscription?.preferredGateway}</span>
                                            </div>
                                        </div>

                                        <div className={styles.userInfoSubDataItem}>
                                            <div className={styles.userInfoSubDataItemIcon}>
                                                <SpriteIcon iconId={'automatic'} width={15} height={15}/>
                                            </div>
                                            <div>
                                                <p>Automatic Charge</p>
                                                <span>{subscription.isAutoRenewal ? 'Yes' : 'No'}</span>
                                            </div>
                                        </div>

                                        <div className={styles.userInfoSubDataItem}>
                                            <div className={styles.userInfoSubDataItemIcon}>
                                                <SpriteIcon iconId={'social-discord'} width={15} height={15}/>
                                            </div>
                                            <div>
                                                <p>Discord</p>
                                                <span>{subscription.customerInfo.discordSocialConnect?.username ?? 'None'}</span>
                                            </div>
                                        </div>

                                        <div className={styles.userInfoSubDataItem} style={{
                                            display: 'none',
                                        }}>
                                            <div className={styles.userInfoSubDataItemIcon}>
                                                <SpriteIcon iconId={'social-telegram'} width={15} height={15}/>
                                            </div>
                                            <div>
                                                <p>Telegram</p>
                                                <span>None</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className={styles.userInfoMetadata}>
                                    <span>Metadata</span>

                                    <div className={styles.userInfoMetadataWrapper}>
                                        {
                                            transformDataObjectIntoArray(subscription.metadata).map((obj, index) => (
                                                <div className={styles.userInfoMetadataItem} key={index}>
                                                    <p>{obj.key}</p>
                                                    <span>{obj.value}</span>
                                                </div>
                                            ))
                                        }
                                    </div>
                                </div>
                            </div>
                            <div className={styles.subscriptionInfo}>
                                <div className={styles.subscriptionInfoMain}>
                                    <div className={styles.subscriptionImage}>
                                        <SpriteIcon iconId={'emptyImg'} width={20} height={20} />
                                    </div>
                                    <div className={styles.subscriptionName}>
                                        <span>{subscription.product.name}</span>
                                        <p>{transformCurrencySign(subscription.product.price.currency) + subscription.product.price.amount} / {transformRecurringInterval(subscription.product.variantName)}</p>
                                    </div>
                                    <div className={styles.subscriptionStatus}>
                                        {/* @ts-ignore */}
                                        <SubscriptionsStatusBadge subscriptionStatus={SubscriptionStatus[subscription.status.toUpperCase()]} />
                                    </div>
                                </div>

                                <div className={styles.subscriptionInfoReplaced} style={{ display: subscription.status !== 'REPLACED' ? 'none' : '' }}>
                                    <SpriteIcon iconId={'caution'} width={15} height={15} />
                                    <p>The subscription has been replaced with a new <a href={`/finances/subscriptions/${subscription.replacedToSubscriptionId}`}>Subscription, ID - {subscription.replacedToSubscriptionId}</a></p>
                                </div>

                                <div className={styles.subscriptionInfoMore}>
                                    <div>
                                        <p>Expires at</p>
                                        <div>
                                            {subscription.activeTo
                                                ? subscription.status === 'CANCELLED' || subscription.status === 'REPLACED' ? '-' : (
                                                    <>
                                                        <span>{formatDateTime(subscription.activeTo).date}</span>
                                                        <p>{formatDateTime(subscription.activeTo).time}</p>
                                                    </>
                                                )
                                                : (<span>Indefinite</span>)
                                            }
                                        </div>
                                    </div>

                                    <div>
                                        <p>{
                                            (subscription.status === 'ACTIVE' || subscription.status === 'CANCELLED')
                                                ? 'Next charge'
                                                : 'Current charge'
                                        }</p>
                                        <div>
                                            {subscription.nextChargeAttemptAt
                                                ? (subscription.status === 'AWAITING_PAYMENT' || subscription.status === 'PAST_DUE' || subscription.status === 'EXPIRED')
                                                    ? <span>{subscription.currentChargeAttempt + ' attempts'}</span>
                                                    : subscription.status === 'CANCELLED' || subscription.status === 'REPLACED' ? '-' : (
                                                        <>
                                                            <span>{formatDateTime(subscription.nextChargeAttemptAt).date}</span>
                                                            <p>{formatDateTime(subscription.nextChargeAttemptAt).time}</p>
                                                        </>
                                                    )
                                                : (<span>-</span>)
                                            }
                                        </div>
                                    </div>

                                    <div>
                                        <p>Created</p>
                                        <div>
                                            <span>{formatDateTime(subscription.createdAt).date}</span>
                                            <p>{formatDateTime(subscription.createdAt).time}</p>
                                        </div>
                                    </div>

                                    <div style={{
                                        borderBottom: !subscription.coupon ? 'none' : '',
                                    }}>
                                        <p>Paid</p>
                                        <div>
                                            {subscription.timeline ? !findAndFormatPaymentDate(subscription.timeline) ? '-' : (
                                                <>
                                                    <span>{findAndFormatPaymentDate(subscription.timeline).date}</span>
                                                    <p>{findAndFormatPaymentDate(subscription.timeline).time}</p>
                                                </>
                                            ) : '-'}
                                        </div>
                                    </div>

                                    <div style={{
                                        display: !subscription.coupon ? 'none' : '',
                                    }}>
                                        <p>Coupon Name, 5$ discount for 3 months</p>
                                        <div>
                                            <span>3/3 months used</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className={styles.history}>
                            <div onClick={() => {
                                !ordersHistoryListIsActive && setOrdersHistoryListIsActive(true);
                            }} className={`${styles.listWrapper} ${ordersHistoryListIsActive && styles.listWrapperActive}`}>
                                <div className={styles.listFace} onClick={() => {
                                    ordersHistoryListIsActive && setOrdersHistoryListIsActive(false);
                                }}>
                                    <span>Orders History</span>
                                    <SpriteIcon iconId={'arrowDown'} width={17} height={10}/>
                                </div>

                                <div className={styles.listBody}>
                                    {
                                        orders &&
                                        (!orders.length && !filterBy)
                                                ? (
                                                <NoDataComponent
                                                    imageSrc={noOrdersImage}
                                                    title={'No Orders'}
                                                    description={'When a customer creates an order, it will appear here.'}
                                                    isSmallHeight
                                                />
                                            ) : (
                                                <>
                                                    <div className={styles.listBodyHeader}>
                                                        <div className={styles.listHeaderButtons}>
                                                            {Object.keys(OrderStatus).map((key, index) => (
                                                                <Button
                                                                    key={index}
                                                                    customClassname={filterBy === key ? styles.activeButton : ''}
                                                                    height={35}
                                                                    onClick={
                                                                        () => {
                                                                            // @ts-ignore
                                                                            filterBy === key ? setFilterBy(null) : setFilterBy(key)
                                                                        }
                                                                    }
                                                                    style={ButtonStyles.BORDERED}
                                                                >{
                                                                    // @ts-ignore
                                                                    OrderStatus[key]
                                                                }</Button>
                                                            ))}

                                                            <Button height={35} icon={{
                                                                id: 'gear',
                                                                width: 20,
                                                                height: 20,
                                                                align: ButtonsIconAlign.LEFT,
                                                            }}/>
                                                        </div>
                                                    </div>

                                                    {
                                                        ordersLoading ? <Spinner /> : <OrdersHistoryTable orders={orders ?? []}/>
                                                    }

                                                    <TablePagination
                                                        totalItems={totalItems}
                                                        pageSize={pageSize}
                                                        setPageSize={setPageSize}
                                                        setCurrentPage={setCurrentPage}
                                                        currentPage={currentPage}
                                                    />
                                                </>
                                            )
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </PageWrapper>
</PageContainer>
)
};

export default SubscriptionsPageSubscriptionDetails;