import React, { FC, useEffect, useRef, useState } from 'react'
import styles from './TablePagination.module.scss'
import ReactPaginate from 'react-paginate'
import { SpriteIcon } from '../UI/SpriteIcon/SpriteIcon'
import formStyles from '../UI/FormComponents/FormComponents.module.scss'
import { debounce } from '../../helpers/debounce'
import Dropdown from "../UI/Dropdown/Dropdown";

interface PaginationProps {
    totalItems: number
    pageSize: number
    setPageSize: (pageSize: number) => void
    setCurrentPage: (currentPage: number) => void
    currentPage: number
    withoutPageSizeSelector?: boolean
    withoutPages?: boolean
    onNextClick?: () => void
    onPrevClick?: () => void
    invertedStyle?: boolean
}

const TablePagination: FC<PaginationProps> = ({
      totalItems,
      setCurrentPage,
      pageSize,
      setPageSize,
      currentPage,
      withoutPageSizeSelector,
      withoutPages,
      onPrevClick,
      onNextClick,
      invertedStyle,
  }) => {
    const pageCount = Math.ceil(totalItems / pageSize)
    const [showInput, setShowInput] = useState(false)
    const [currentBreakElement, setCurrentBreakElement] = useState<Element | null>(null)
    const inputRef = useRef(document.createElement('input'))

    const handlePageClick = (event: { selected: number }) => {
        setCurrentPage(event.selected)
    }

    useEffect(() => {
        document.querySelectorAll(`.${styles.break}`).forEach((element, key) => {
            element.id = `${styles.break + key}`
            element.addEventListener('click', event => {
                setCurrentBreakElement(element)
                event.preventDefault()
                event.stopPropagation()
                setShowInput(true)
            })
        })

        inputRef.current.value = (+currentPage + 1).toString()
    })

    useEffect(() => {
        if (showInput && currentBreakElement) {
            const debouncedSetCurrentPage = debounce((value: number) => {
                setCurrentPage(value)
                inputRef.current.classList.add(styles.hide)
                currentBreakElement.classList.remove(styles.hide)
                setShowInput(false)
            }, 400)
            inputRef.current.classList.remove(styles.hide)
            inputRef.current.type = 'number'
            inputRef.current.className = formStyles.input
            inputRef.current.addEventListener('input', event => {
                // @ts-ignore
                const value = +event.target?.value - 1
                const pageCountValue = pageCount - 1
                debouncedSetCurrentPage(value > pageCountValue ? pageCountValue : value)
            })
            inputRef.current.value = (+currentPage + 1).toString()
            inputRef.current.max = pageCount.toString()
            inputRef.current.min = '1'
            inputRef.current.style.width = '80px'

            currentBreakElement.insertAdjacentElement('afterend', inputRef.current)
            currentBreakElement.classList.add(styles.hide)
            setCurrentBreakElement(null)
        }
    }, [showInput, currentBreakElement])

    return (
        <div className={styles.paginationWrapper}>
            <div className={styles.paginationInfo}>
                Showing {currentPage * pageSize + 1} to {currentPage + 1 === pageCount ? totalItems : pageSize * (currentPage + 1)} of {totalItems}
            </div>

            <div
                className={`${styles.pagination} ${
                    withoutPageSizeSelector && styles.withoutPageSizeSelector
                } ${invertedStyle && styles.inverted}`}
            >
                <div className={`${styles.paginate} ${withoutPages && styles.withoutPages}`}>
                    <div
                        className={`${styles.nextPrevCustom} ${styles.next}`}
                        onClick={
                            onPrevClick ? onPrevClick : () => currentPage > 0 && setCurrentPage(currentPage - 1)
                        }
                    >
                        <SpriteIcon iconId={'arrow'} width={14} height={12}/>
                    </div>
                    {!withoutPages && (
                        <ReactPaginate
                            breakLabel='...'
                            nextLabel=''
                            onPageChange={handlePageClick}
                            pageRangeDisplayed={5}
                            initialPage={currentPage}
                            pageCount={pageCount}
                            forcePage={currentPage}
                            previousLabel=''
                            renderOnZeroPageCount={() => null}
                            containerClassName={styles.paginateContainer}
                            previousClassName={styles.paginatePrevNext}
                            nextClassName={styles.paginatePrevNext}
                            pageClassName={styles.paginatePage}
                            activeClassName={styles.paginateActive}
                            breakClassName={styles.break}
                        />
                    )}

                    <div
                        className={`${styles.nextPrevCustom} ${styles.prev}`}
                        onClick={
                            onNextClick
                                ? onNextClick
                                : () => currentPage + 1 < pageCount && setCurrentPage(currentPage + 1)
                        }
                    >
                        <SpriteIcon iconId={'arrow'} width={14} height={12}/>
                    </div>
                </div>
            </div>

            {!withoutPageSizeSelector && (
                <div className={styles.pageSizeController}>
                    Show {' '}

                    <Dropdown options={[
                        {
                            text: '5',
                            onClick: () => setPageSize && setPageSize(5)
                        },
                        {
                            text: '10',
                            onClick: () => setPageSize && setPageSize(10)
                        },
                        {
                            text: '20',
                            onClick: () => setPageSize && setPageSize(20)
                        },
                    ]} title={pageSize.toString()}/>
                </div>
            )}
        </div>
    )
}

export default TablePagination
