import {FC} from "react";
import {useParams} from "react-router-dom";
import PageWrapper from "../../components/PageWrapper/PageWrapper";
import PageContainer from "../../components/UI/PageContainer/PageContainer";
import PageHeader from "../../components/PageHeader/PageHeader";
import styles from './AdvancedAnalyticsReportPage.module.scss';

const AdvancedAnalyticsReportPage: FC = () => {
    const { id } = useParams();

    return (
        <PageWrapper>
            <PageContainer>
                <PageHeader title={'Created Advanced Analytics'} />
                <h1>Report: {id}</h1>
            </PageContainer>
        </PageWrapper>
    )
};

export default AdvancedAnalyticsReportPage;