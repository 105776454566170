import React, {FC} from 'react';
import RedesignedTable from "../../RedesignedTable/RedesignedTable";
import {SpriteIcon} from '../../UI/SpriteIcon/SpriteIcon';
import OrdersStatusBadge from '../../UI/StatusBadges/OrdersStatusBadge';
import Dropdown from '../../UI/Dropdown/Dropdown';
import {OrderStatus} from '../../../types/OrderStatus/OrderStatus';
import styles from './OrdersHistoryTable.module.scss';
import defaultAvatarMock from '../../Tables/defaultAvatarMock.png';
import {Order} from "../../../types/Order/Order";
import {TelegramBot} from "../../../types/TelegramBot/TelegramBot";
import {MenuProps} from "antd";
import DropdownMenuItem from "../../UI/DropdownMenuItem/DropdownMenuItem";
import TableDropdown from "../../UI/TableDropdown/TableDropdown";
import PaymentTypeBadge from "../../PaymentTypeBadge/PaymentTypeBadge";
import TruncatedText from "../../TruncatedText/TruncatedText";
import UserDefaultAvatar from "../../UI/UserDefaultAvatar/UserDefaultAvatar";

interface OrdersHistoryTableProps {
    orders: Order[];
    hoverEffect?: boolean;
    menuIsHidden?: boolean;
    onRowClick?: (orderId: string) => void;
}

const OrdersHistoryTable: FC<OrdersHistoryTableProps> = ({ orders, hoverEffect, menuIsHidden, onRowClick }) => {
    const formatOrderPrice = (currency: string, amount: number): string => {
        switch (currency) {
            case 'EUR':
                return `€${amount}`
            default:
                return `$${amount}`
        }
    }

    const transformDate = (isoDateString: string): Record<string, string> => {
        const date = new Date(isoDateString);
        const now = new Date();

        const timeAgo = (date: Date): string => {
            // @ts-ignore
            const secondsAgo = Math.floor((now - date) / 1000);
            const minutesAgo = Math.floor(secondsAgo / 60);
            const hoursAgo = Math.floor(minutesAgo / 60);
            const daysAgo = Math.floor(hoursAgo / 24);

            if (secondsAgo < 60) {
                return `${secondsAgo} seconds ago`;
            } else if (minutesAgo < 60) {
                return `${minutesAgo} minutes ago`;
            } else if (hoursAgo < 24) {
                return `${hoursAgo} hours ago`;
            } else {
                return `${daysAgo} days ago`;
            }
        }

        const formatTime = (date: any): any => {
            const options = {
                hour: 'numeric',
                minute: 'numeric',
                hour12: true,
                timeZoneName: 'short'
            };
            return date.toLocaleTimeString('en-US', options);
        }

        return {
            timeAgo: timeAgo(date),
            formattedTime: formatTime(date)
        };
    }

    const headers = [
        { title: 'Customer Info' },
        { title: 'Status' },
        { title: 'Payment Type' },
        { title: 'Date & Time' },
        { title: 'Revenue' },
        { title: '' },
    ];

    const dropdownMenu = (): any => {
        const items: MenuProps['items'] = [
            {
                label: <DropdownMenuItem
                    text={'Edit Order'}
                    iconId={'edit-pen'}
                />,
                key: '0',
                onClick: () => {}
            },
            {
                label: <DropdownMenuItem
                    text={'Delete Order'}
                    iconId={'delete-bin'}
                />,
                key: '1',
                onClick: () => {}
            }
        ]

        return { items };
    };

    const rowsData = orders.map(order => [
        <div className={styles.orderDetails} onClick={() => {
                onRowClick && onRowClick(order.id);
            }}>
            <div className={styles.orderDetailsAvatar}>
                <UserDefaultAvatar text={order.customerEmail} width={24} height={24} />
            </div>
            <div className={styles.orderDetailsText}>
                <span>{order.customerEmail}</span>
                <TruncatedText as={'p'} maxLength={18}>
                    {order.id}
                </TruncatedText>
            </div>
        </div>,

        <div className={styles.status} onClick={() => {
            onRowClick && onRowClick(order.id);
        }}>
            <OrdersStatusBadge orderStatus={order.status} />
        </div>,

        <div className={styles.paymentType} onClick={() => {
            onRowClick && onRowClick(order.id);
        }}>
            <PaymentTypeBadge gateway={order.gateway} />
        </div>,

        <div className={styles.dateAndTime} onClick={() => {
            onRowClick && onRowClick(order.id);
        }}>
            <span>{transformDate(order.createdAt).timeAgo}</span>
            <p>{transformDate(order.createdAt).formattedTime}</p>
        </div>,

        <div className={styles.value} onClick={() => {
            onRowClick && onRowClick(order.id);
        }}>
            <span>{formatOrderPrice(order.endPrice.currency, order.endPrice.amount)}</span>
        </div>,

        !menuIsHidden && <TableDropdown menuItems={dropdownMenu()} />
    ]);

    return <RedesignedTable rowsHoverEffect={hoverEffect} headers={headers} rowsData={rowsData} />;
};

export default OrdersHistoryTable;