import React, { useEffect, useState } from 'react'
import styles from './OrdersPage.module.scss'
import PageContainer from '../../components/UI/PageContainer/PageContainer'
import PageWrapper from '../../components/PageWrapper/PageWrapper'
import PageHeader from '../../components/PageHeader/PageHeader'
import SearchInput, { SearchInputStyles } from '../../components/UI/SearchInput/SearchInput'
import Pagination from '../../components/UI/Pagination/Pagination'

import OrdersTable from '../../components/Tables/OrdersTable'
import { useAPI } from '../../hooks/useAPI'
import { OrdersService } from '../../API/OrdersService'
import { useAppContext } from '../../hooks/useAppContext'
import Spinner from '../../components/UI/Spinner/Spinner'
import { Order } from '../../types/Order/Order'
import PageTableHeader from '../../components/PageTableHeader/PageTableHeader'
import { useFirstLoading } from '../../hooks/useFirstLoading'
import PageLoading from '../../components/UI/PageLoading/PageLoading'
import NoDataComponent from '../../components/NoDataComponent/NoDataComponent'
import noOrdersImage from '../../assets/images/noOrders.png'
import { useDebounce } from '../../hooks/useDebounce'
import { usePageTitle } from '../../hooks/usePageTitle'
import { OrderStatus } from '../../types/OrderStatus/OrderStatus'
import { ShopForUserPermissions } from '../../types/ShopForUserPermissions/ShopForUserPermissions'
import OrdersHistoryTable from "../../components/Tables/OrdersHistoryTable/OrdersHistoryTable";
import TablePagination from "../../components/TablePagination/TablePagination";
import {usePagination} from "../../hooks/usePagination";
import {useNavigate} from "react-router-dom";

const OrdersPage = () => {
	const [searchValue, setSearchValue] = useState('')
	const {
		totalItems,
		currentPage,
		pageSize,
		setPageSize,
		setCurrentPage,
		updateTotalItems
	} = usePagination();
	const { shop } = useAppContext()
	const navigate = useNavigate();

	const [statusFilter, setStatusFilter] = useState<OrderStatus | null>(null)
	const isAdmin =
		!!shop?.permissions.includes(ShopForUserPermissions.OWNER) ||
		!!shop?.permissions.includes(ShopForUserPermissions.ADMIN)
	const isOrdersViewAvailable =
		!!shop?.permissions.includes(ShopForUserPermissions.ORDERS_VIEW) || isAdmin

	usePageTitle(`Orders`)

	const [isGetOrdersLoading, orders, getOrders] = useAPI<Order[]>(
		() =>
			OrdersService.getAll(shop?.id || '', statusFilter, searchValue, currentPage + 1, pageSize),
		false,
		undefined,
		{
			onSuccess: response => {
				updateTotalItems(response.headers['x-pagination-total'])
			},
		}
	)

	const isFirstLoading = useFirstLoading(isGetOrdersLoading)
	useDebounce(searchValue, getOrders, 400)

	useEffect(() => {
		if (shop && isOrdersViewAvailable) {
			getOrders()
		}
	}, [shop, currentPage, pageSize, statusFilter])

	if (isFirstLoading) {
		return <PageLoading />
	}

	if (!isOrdersViewAvailable)
		return (
			<NoDataComponent
				imageSrc={noOrdersImage}
				title={'Access Denied'}
				description={
					"You don't have permissions to view this page on this team. Contact your administrator to access this dashboard."
				}
			/>
		)

	if (!orders?.length && !searchValue && !statusFilter) {
		return (
			<NoDataComponent
				imageSrc={noOrdersImage}
				title={'No Orders'}
				description={'When a customer creates an order on your storefront, it will appear here.'}
			/>
		)
	}

	return (
		<PageContainer>
			<PageWrapper>
				<div className={styles.ordersPage}>
					<PageHeader title={'Orders'}>
						<div className={styles.headerEnd}>
							<SearchInput
								value={searchValue}
								setValue={setSearchValue}
								style={SearchInputStyles.secondary}
								placeholder={'Search for an order by email or name'}
							/>
						</div>
					</PageHeader>

					<div className={styles.filters}>
						<div
							className={`${styles.filterButton} ${statusFilter === null && styles.active}`}
							onClick={() => setStatusFilter(null)}
						>
							All
						</div>
						<div
							className={`${styles.filterButton} ${
								statusFilter === OrderStatus.COMPLETED && styles.active
							}`}
							onClick={() => setStatusFilter(OrderStatus.COMPLETED)}
						>
							Completed
						</div>
						<div
							className={`${styles.filterButton} ${
								statusFilter === OrderStatus.CANCELLED && styles.active
							}`}
							onClick={() => setStatusFilter(OrderStatus.CANCELLED)}
						>
							Canceled
						</div>
						<div
							className={`${styles.filterButton} ${
								statusFilter === OrderStatus.FULL_DELIVERY_FAILURE && styles.active
							}`}
							onClick={() => setStatusFilter(OrderStatus.FULL_DELIVERY_FAILURE)}
						>
							Failed
						</div>
						<div
							className={`${styles.filterButton} ${
								statusFilter === OrderStatus.PENDING && styles.active
							}`}
							onClick={() => setStatusFilter(OrderStatus.PENDING)}
						>
							Pending
						</div>
					</div>

					{isGetOrdersLoading ? (
						<Spinner />
					) : (
						orders && (
							<>
								<OrdersHistoryTable
									orders={orders}
									onRowClick={(id) => {
										navigate(`/finances/orders/${id}`);
									}}
									hoverEffect
									menuIsHidden
								/>

								<TablePagination
									totalItems={totalItems}
									pageSize={pageSize}
									setPageSize={setPageSize}
									setCurrentPage={setCurrentPage}
									currentPage={currentPage}
								/>
							</>
						)
					)}
				</div>
			</PageWrapper>
		</PageContainer>
	)
}

export default OrdersPage
